import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface User {
  id: string;
  email: string;
  image: string | null;
  submerchantId: string | null;
  fullname: string;
  update_flag: string;
  branchId: string;
  accountNo: string;
  city: string;
  state: string;
  accountName: string;
  dateOfBirth: string;
  gender: string;
  bank: string;
  bankcode: string;
  bvn: string;
  message: string;
}

interface UserState {
  user: User | null;
  isAuthenticated: boolean;
  loading: boolean;
  update_flag: number; // 0 or 1 based on form submission success
}

const initialState: UserState = {
  user: null,
  isAuthenticated: false,
  loading: false,
  update_flag: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.update_flag = parseInt(action.payload.update_flag);
    },
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    setUpdateFlag(state, action: PayloadAction<number>) {
      if (state.user) {
        state.user.update_flag = action.payload.toString();
      }
      state.update_flag = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.update_flag = 0;
    },
  },
});

export const {
  setUser,
  setIsAuthenticated,
  setUpdateFlag,
  setLoading,
  logout,
} = userSlice.actions;

export default userSlice.reducer;
