import React from "react";
import default_avatar from "../../assets/images/default_avatar.jpg";

const AvatarUploadForm = () => {
  return (
    <div className="row wrapper">
      <div className="col-10 col-lg-8">
        <form className="shadow rounded bg-body p-4">
          <h2 className="mb-4">Upload Avatar</h2>

          <div className="mb-3">
            <div className="flex items-center">
              <div className="me-8">
                <figure className="avatar item-r">
                  <img
                    src={default_avatar}
                    className="rounded-full"
                    alt="image"
                  />
                </figure>
              </div>
              <div className="input-foam flex-1">
                <label className="form-label" htmlFor="customFile">
                  Choose Avatar
                </label>
                <input
                  type="file"
                  name="avatar"
                  className="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm py-2 px-3 w-full"
                  id="customFile"
                  accept="image/*"
                />
              </div>
            </div>
          </div>

          <button
            id="register_button"
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md w-full"
          >
            Upload
          </button>
        </form>
      </div>
    </div>
  );
};

export default AvatarUploadForm;
