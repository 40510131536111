import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  setIsAuthenticated,
  setUser,
  setLoading,
} from "../../features/userSlice";

// Define the response type
interface User {
  id: string;
  email: string;
  image: string | null;
  submerchantId: string | null;
  fullname: string;
  update_flag: string; // This should be a string as per your provided object
  branchId: string;
  accountNo: string;
  city: string;
  state: string;
  accountName: string;
  dateOfBirth: string;
  gender: string;
  bank: string;
  bankcode: string;
  bvn: string;
  message: string;
}

type UsersResponse = User[];

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASEURL}/eventhost`,
  }),

  endpoints: (builder) => ({
    getHost: builder.query<UsersResponse, string>({
      query: (id) => `/${id}`, // Ensure correct URL formatting for your API
      transformResponse: (response: UsersResponse) => response,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        dispatch(setLoading(true));
        try {
          const { data } = await queryFulfilled;
          // Assuming you want to set the first user in the array
          if (data.length > 0) {
            dispatch(setUser(data[0]));
            dispatch(setIsAuthenticated(true));
          }
        } catch (error) {
          console.error(error);
        } finally {
          dispatch(setLoading(false));
        }
      },
    }),
    updateProfile: builder.mutation({
      query(body) {
        return {
          url: "/changepass",
          method: "POST",
          body,
        };
      },
    }),
    usersFile: builder.query({
      query: (id) => `/${id}`,
    }),

    uploadAvatar: builder.mutation({
      query(body) {
        return {
          url: "/updatepics",
          method: "POST",
          body,
        };
      },
    }),
    forgotPassword: builder.mutation({
      query(body) {
        return {
          url: "/forgotpassemail",
          method: "POST",
          body,
        };
      },
    }),
    resetPassword: builder.mutation({
      query: ({ email, newpassword }) => ({
        url: "/resetpass",
        method: "POST",
        body: { email, newpassword },
      }),
    }),
  }),
});

export const {
  useGetHostQuery,
  useUpdateProfileMutation,
  useUploadAvatarMutation,
  useForgotPasswordMutation,
  useUsersFileQuery,
  useResetPasswordMutation,
} = userApi;
