import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import {
  useUpdateProfileMutation,
  useUsersFileQuery,
} from "../../redux/api/userApi";
import toast from "react-hot-toast";
import default_avatar from "../../assets/images/default_avatar.jpg";
import { setUpdateStatus } from "../../features/eventSlice";

interface User {
  id: string;
  email: string;
  image: string | null;
  submerchantId: string | null;
  fullname: string | null;
  lastname: string | null;
  branchId: string;
  accountNo: string;
  city: string;
  state: string;
  accountName: string;
  dateOfBirth: string;
  gender: string;
  bank: string;
  bankcode: string;
  bvn: string;
  message: string;
}
// Define an interface for CSS classes
interface CSSClasses {
  cls1: string;
  cls2: string;
}

const HostProfile = () => {
  // Define CSS classes
  const classes: CSSClasses = {
    cls1: "fill:#1877f2;",
    cls2: "fill:#fff;",
  };

  const user = useSelector(
    (state: RootState) => state.auth.user
  ) as User | null;
  console.log(user);
  const [dateCreated, setDateCreated] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [about, setAbout] = useState<string>("");
  const [bankCode, setBankCode] = useState<string>("");
  const [instagram, setInstagram] = useState<string>("");
  const [facebook, setFacebook] = useState<string>("");
  const [twitter, setTwitter] = useState<string>("");

  const [fullname, setFullname] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateProfile, { isLoading, error, isSuccess }] =
    useUpdateProfileMutation();
  const updateStatus = useSelector(
    (state: RootState) => state.event.updateStatus
  );

  const { data, refetch } = useUsersFileQuery(user?.id!, {
    skip: !user?.id,
  });
  console.log(data);
  useEffect(() => {
    if (user) {
      setFullname(user?.fullname || "");
      setEmail(user?.email || "");
    }
    if (data) {
      setAbout(data[0]?.about || "");
      setDateCreated(data[0]?.dateCreated || "");
      setBankCode(data[0]?.bankCode || "");
      setInstagram(data[0]?.instagram || "");
      setFacebook(data[0]?.facebook || "");
      setTwitter(data[0]?.twitter || "");
    }

    if (error) {
      toast.error((error as any)?.data?.message);
    }
    if (isSuccess) {
      toast.success("User Updated");
      navigate(0);
    }
  }, [user, error, isSuccess, navigate]);

  useEffect(() => {
    if (updateStatus || data || user) {
      refetch();
      dispatch(setUpdateStatus(false)); // reset update status to false
    }
  }, [updateStatus, data, user, refetch, dispatch]);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();

    const userData = {
      fullname,
      email,
    };
    updateProfile(userData);
  };

  const handlePasswordChange = () => {
    navigate("/profile_change");
  };

  const handleAvatarUpload = () => {
    navigate("/avatar_upload");
  };

  return (
    <div className="h-full bg-[#0A0D36] p-8 ">
      <div className="bg-white rounded-lg shadow-xl pb-8 mb-12">
        <div className="w-full h-[250px]">
          <img
            src="https://vojislavd.com/ta-template-demo/assets/img/profile-background.jpg"
            className="w-full h-full rounded-tl-lg rounded-tr-lg"
          />
        </div>
        <div className="flex flex-col items-center -mt-20">
          <button onClick={handleAvatarUpload}>
            <img
              src={default_avatar}
              className="w-40 border-4 border-white rounded-full"
            />
          </button>
          <div className="flex items-center space-x-2 mt-2">
            <p className="text-2xl">{user?.fullname}</p>
            <span className="bg-blue-500 rounded-full p-1" title="Verified">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="text-gray-100 h-2.5 w-2.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={4}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            </span>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-center lg:items-end justify-end px-8 mt-2">
          <div className="flex items-center space-x-4 mt-2"></div>
        </div>
      </div>

      <div className="bg-white mx-auto justify-center max-w-2xl shadow overflow-hidden sm:rounded-lg">
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Full name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user?.fullname}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user?.email}{" "}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Account Name
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user?.accountName}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Account Number
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user?.accountNo}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Date Joined</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {dateCreated}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Sort Code</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {bankCode}{" "}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Instagram</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {instagram}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Twitter</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {twitter}{" "}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Facebook</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {facebook}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">About</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {about}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    // </div>
    // </div>
  );
};

export default HostProfile;
