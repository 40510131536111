import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { userApi } from "./userApi";
import {
  setUser,
  setIsAuthenticated,
  setLoading,
} from "../../features/userSlice";

interface User {
  id: string;
  email: string;
  image: string | null;
  submerchantId: string | null;
  fullname: string;
  update_flag: string;
  branchId: string;
  accountNo: string;
  city: string;
  state: string;
  accountName: string;
  dateOfBirth: string;
  gender: string;
  bank: string;
  bankcode: string;
  bvn: string;
  message: string;
  agentId?: number | null;
  country: string;
  phone: string;
}

interface LoginResponse extends User {}

// Define the expected type for the error response
interface LoginError {
  error: boolean;
  message: string;
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASEURL}/eventhost`,
  }),
  endpoints: (builder) => ({
    register: builder.mutation<User, any>({
      query(body) {
        return {
          url: "/register",
          method: "POST",
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLoading(true));
          const { data } = await queryFulfilled;
          if (data.agentId) {
            await dispatch(
              userApi.endpoints.getHost.initiate(data.agentId.toString())
            );
          }
          dispatch(setUser(data));
          dispatch(setIsAuthenticated(true));
          dispatch(setLoading(false));
        } catch (error) {
          console.log(error);
          dispatch(setLoading(false));
        }
      },
    }),

    login: builder.mutation<LoginResponse, { email: string; password: string }>(
      {
        query(body) {
          return {
            url: "/login",
            method: "POST",
            body,
          };
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            dispatch(setLoading(true));
            const { data } = await queryFulfilled;
            if (data.agentId) {
              await dispatch(
                userApi.endpoints.getHost.initiate(data.agentId.toString())
              );
            }
            dispatch(setUser(data));
            dispatch(setIsAuthenticated(true));
            dispatch(setLoading(false));
          } catch (error) {
            console.log(error);
            dispatch(setLoading(false));
          }
        },
      }
    ),
    logout: builder.query({
      query: () => "/logout",
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation, useLazyLogoutQuery } =
  authApi;
