import React from 'react';

const TermsOfUse = () => {
  return (
    <div className="w-3/4 bg-[#04152d] p-4 text-white leading-8">
      <h2 className="text-[32px] font-bold mb-4">Terms of Website Use (UK)</h2>
      <h3>
        PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE
      </h3>
      1. What's in these terms?
      <br></br>
      1.1. These terms tell you the rules for using our website.
      <br></br>
      <br />
      2. Who we are and how to contact us <br></br>
      2.1. www.motickets.co.uk is a site ('our site') operated by Atendi Ltd
      (‘We’). We are registered in England and Wales under company number
      14185160 and have our registered office at 150 Midsummer Boulevard, Milton
      Keynes, MK9 1FD, England. We are a limited company. <br></br>
      2.2. To contact us, please email help@motickets.co.uk or telephone our
      customer service line on +441908103520. <br></br> <br />
      3. By using our site you accept these terms
      <br></br>
      3.1. By using our site, you confirm that you accept these terms of use and
      that you agree to comply with them.
      <br></br>
      3.2. If you do not agree to these terms, you must not use our site.{' '}
      <br></br>
      3.3. We recommend that you print a copy of these terms for future
      reference.<br></br> <br></br>
      4. There are other terms that may apply to you
      <br></br>
      4.1. These terms of use refer to the following additional terms, which
      also apply to your use of our site: <br></br>
      (a) Our Privacy Policy [(Privacy Policy Domain Address)], which sets out
      the terms on which we process any personal data we collect from you, or
      that you provide to us. By using our site, you consent to such processing
      and you warrant that all data provided by you is accurate.
      <br></br>
      (b) Our Acceptable Use Policy [(Acceptable Use Policy Domain Address)],
      which sets out the permitted uses and prohibited uses of our site. When
      using our site, you must comply with this Acceptable Use Policy.
      <br></br>
      (c) Our Cookie Policy [(Cookie Policy Domain Address)], which sets out
      information about the cookies on our site. <br></br>
      4.2. If you purchase goods from our site, our Terms and Conditions [Terms
      and Conditions Address Link] will apply to the sales.
      <br></br>
      <br></br>
      5. We may make changes to these terms <br></br>
      5.1. We amend these terms from time to time. Every time you wish to use
      our site, please check these terms to ensure you understand the terms that
      apply at that time. These terms were most recently updated on 12th
      February 2024. <br></br>
      <br></br>
      6. We may make changes to our site. <br></br>
      6.1. We may update and change our site from time to time to reflect
      changes to our products, our users' needs, and our business priorities. We
      will try to give you reasonable notice of any major changes.
      <br></br>
      <br></br>
      7. We may suspend or withdraw our site <br></br>
      7.1. Our site is made available free of charge.
      <br></br>
      7.2. We do not guarantee that our site, or any content on it, will always
      be available or be uninterrupted. We may suspend or withdraw or restrict
      the availability of all or any part of our site for business and
      operational reasons. We will try to give you reasonable notice of any
      suspension or withdrawal.
      <br></br>
      7.3. You are also responsible for ensuring that all persons who access our
      site through your internet connection are aware of these terms of use and
      other applicable terms and conditions, and that they comply with them.{' '}
      <br></br> <br></br>
      8. You must keep your account details safe
      <br></br>
      8.1. If you choose, or you are provided with, a user identification code,
      password or any other piece of information as part of our security
      procedures, you must treat such information as confidential. You must not
      disclose it to any third party.
      <br></br>
      8.2. We have the right to disable any user identification code or
      password, whether chosen by you or allocated by us, at any time, if in our
      reasonable opinion you have failed to comply with any of the provisions of
      these terms of use.
      <br></br>
      8.3. If you know or suspect that anyone other than you knows your user
      identification code or password, you must promptly notify us at
      help@motickets.co.uk.
      <br></br> <br />
      9. How you may use material on our site
      <br></br>
      9.1. We are the owner or the licensee of all intellectual property rights
      in our site, and in the material published on it. Those works are
      protected by copyright laws and treaties around the world. All such rights
      are reserved.
      <br></br>
      9.2. You may print off one copy, and may download extracts, of any page(s)
      from our site for your personal use and you may draw the attention of
      others within your organisation to content posted on our site.
      <br></br>
      9.3. You must not modify the paper or digital copies of any materials you
      have printed off or downloaded in any way, and you must not use any
      illustrations, photographs, video or audio sequences or any graphics
      separately from any accompanying text.
      <br></br>
      9.4. Our status (and that of any identified contributors) as the authors
      of content on our site must always be acknowledged.
      <br></br>
      9.5. You must not use any part of the content on our site for commercial
      purposes without obtaining a licence to do so from us or our licensors.
      <br></br>
      9.6. If you print off, copy or download any part of our site in breach of
      these terms of use, your right to use our site will cease immediately and
      you must, at our option, return or destroy any copies of the materials you
      have made.
      <br></br>
      <br></br>
      10. Do not rely on information on this site
      <br></br>
      10.1. The content on our site is provided for general information only. It
      is not intended to amount to advice on which you should rely. You must
      obtain professional or specialist advice before taking, or refraining
      from, any action on the basis of the content on our site.
      <br></br>
      10.2. Although we make reasonable efforts to update the information on our
      site, we make no representations, warranties or guarantees, whether
      express or implied, that the content on our site is accurate, complete, or
      up to date.
      <br></br>
      <br></br>
      11. We are not responsible for websites we link to
      <br></br>
      11.1. Where our site contains links to other sites and resources provided
      by third parties, these links are provided for your information only. Such
      links should not be interpreted as approval by us of those linked websites
      or information you may obtain from them.
      <br></br>
      11.2. We have no control over the contents of those sites or resources.
      <br></br>
      <br></br>
      12. User-generated content is not approved by us
      <br></br>
      12.1. This website may include information and materials uploaded by other
      users of the site, including to bulletin boards and chat rooms. This
      information and these materials have not been verified or approved by us.
      The views expressed by other users on our site do not represent our views
      or values.
      <br></br>
      12.2. If you wish to complain about information and materials uploaded by
      other users please contact us at help@motickets.co.uk.
      <br></br>
      <br></br>
      13. Our responsibility for loss or damage suffered by you
      <br></br>
      13.1. Whether you are a consumer or a business user:
      <br></br>
      (a) We do not exclude or limit in any way our liability to you where it
      would be unlawful to do so. This includes liability for death or personal
      injury caused by our negligence or the negligence of our employees,
      agents, or subcontractors and for fraud or fraudulent misrepresentation.
      <br></br>
      (b) Different limitations and exclusions of liability will apply to
      liability arising as a result of the supply of any products to you, which
      will be set out in our Terms and Conditions of supply [Terms and
      Conditions Address Link].
      <br></br>
      13.2. If you are a business user:
      <br></br>
      (a) We exclude all implied conditions, warranties, representations, or
      other terms that may apply to our site or any content on it.
      <br></br>
      (b) We will not be liable to you for any loss or damage, whether in
      contract, tort (including negligence), breach of statutory duty, or
      otherwise, even if foreseeable, arising under or in connection with:
      <br></br>
      (i) use of, or inability to use, our site; or
      <br></br>
      (ii) use of or reliance on any content displayed on our site.
      <br></br>
      (c) In particular, We will not be liable for:
      <br></br>
      (i) loss of profits, sales, business, or revenue;
      <br></br>
      (ii) business interruption;
      <br></br>
      (iii) loss of anticipated savings;
      <br></br>
      (d) loss of business opportunity, goodwill, or reputation; or
      <br></br>
      (e) any indirect or consequential loss or damage.
      <br></br>
      <br></br>
      13.3. If you are a consumer user: (a) Please note that we only provide our
      site for domestic and private use. You agree not to use our site for any
      commercial or business purposes, and we have no liability to you for any
      loss of profit, loss of business, business interruption, or loss of
      business opportunity.
      <br></br>
      (b) If defective digital content that we have supplied, damages a device
      or digital content belonging to you and this is caused by our failure to
      use reasonable care and skill, we will either repair the damage or pay you
      compensation. However, we will not be liable for damage that you could
      have avoided by following our advice to apply an update offered to you
      free of charge or for damage that was caused by you failing to correctly
      follow installation instructions or to have in place the minimum system
      requirements advised by us.
      <br></br>
      (c) Refunds are only guaranteed if the event is cancelled or in accordance
      with the event managers refund policy for each event.
      <br></br>
      (d) We have no liability if the event manager chooses to use photographs
      or videos taken at the event.
      <br></br>
      <br></br>
      14. Uploading content to our site
      <br></br>
      14.1. Whenever you make use of a feature that allows you to upload content
      to our site, or to make contact with other users of our site, you must
      comply with the content standards set out in our Acceptable Use Policy
      [Acceptable Use Policy Domain Address].
      <br></br>
      14.2. You warrant that any such contribution does comply with those
      standards, and you will be liable to us and indemnify us for any breach of
      that warranty. This means you will be responsible for any loss or damage
      we suffer as a result of your breach of warranty.
      <br></br>
      14.3. Any content you upload to our site will be considered
      non-confidential and non-proprietary. You retain all of your ownership
      rights in your content, but you are required to grant us [and other users
      of our site] a limited licence to use, store and copy that content and to
      distribute and make it available to third parties. The rights you license
      to us are described in the section below "Rights you are giving us to use
      material you upload".
      <br></br>
      14.4. We also have the right to disclose your identity to any third party
      who is claiming that any content posted or uploaded by you to our site
      constitutes a violation of their intellectual property rights, or of their
      right to privacy.
      <br></br>
      14.5. We have the right to remove any posting you make on our site if, in
      our opinion, your post does not comply with the content standards set out
      in our Acceptable Use Policy [Acceptable Use Policy Domain Address].
      <br></br>
      14.6. You are solely responsible for securing and backing up your content.
      <br></br>
      <br></br>
      15. Rights you are giving us to use material you upload
      <br></br>
      15.1. When you upload or post content to our site, you grant us the
      following rights to use that content:
      <br></br>
      (a) a worldwide, non-exclusive, royalty-free, transferable licence to use,
      reproduce, distribute, prepare derivative works of, display, and perform
      that user-generated content in connection with the service provided by the
      website and across different media including to promote the site or the
      service to expire when the user deletes content from the site;
      <br></br>
      (b) a worldwide, non-exclusive, royalty-free, transferable licence for
      other users, partners, or advertisers to use the content for their
      purposes to expire when the user deletes content from the site.
      <br></br>
      <br></br>
      16. We are not responsible for viruses and you must not introduce them
      <br></br>
      16.1. We do not guarantee that our site will be secure or free from bugs
      or viruses.
      <br></br>
      16.2. You are responsible for configuring your information technology,
      computer programmes and platform to access our site. You should use your
      own virus protection software.
      <br></br>
      16.3. You must not misuse our site by knowingly introducing viruses,
      trojans, worms, logic bombs or other material that is malicious or
      technologically harmful. You must not attempt to gain unauthorised access
      to our site, the server on which our site is stored or any server,
      computer or database connected to our site. You must not attack our site
      via a denial-of-service attack or a distributed denial-of service attack.
      By breaching this provision, you would commit a criminal offence under the
      Computer Misuse Act 1990. We will report any such breach to the relevant
      law enforcement authorities, and we will co-operate with those authorities
      by disclosing your identity to them. In the event of such a breach, your
      right to use our site will cease immediately.
      <br></br>
      <br></br>
      17. Rules about linking to our site
      <br></br>
      17.1. You may link to our home page, provided you do so in a way that is
      fair and legal and does not damage our reputation or take advantage of it.
      <br></br>
      17.2. You must not establish a link in such a way as to suggest any form
      of association, approval or endorsement on our part where none exists.
      <br></br>
      17.3. You must not establish a link to our site in any website that is not
      owned by you.
      <br></br>
      17.4. Our site must not be framed on any other site, nor may you create a
      link to any part of our site other than the home page.
      <br></br>
      17.5. We reserve the right to withdraw linking permission without notice.
      <br></br>
      17.6. The website in which you are linking must comply in all respects
      with the content standards set out in our Acceptable Use Policy
      [Acceptable Use Policy Domain Address].
      <br></br>
      17.7. If you wish to link to or make any use of content on our site other
      than that set out above, please contact help@motickets.co.uk.
      <br></br>
      <br></br>
      18. Which country's laws apply to any disputes?
      <br></br>
      18.1. If you are a consumer, please note that these terms of use, their
      subject matter, and their formation, are governed by English law. You and
      we both agree that the courts of England and Wales will have exclusive
      jurisdiction except that if you are a resident of Northern Ireland, you
      may also bring proceedings in Northern Ireland, and if you are resident of
      Scotland, you may also bring proceedings in Scotland.
      <br></br>
      18.2. If you are a business, these terms of use, their subject matter, and
      their formation (and any non-contractual disputes or claims) are governed
      by English law. We both agree to the exclusive jurisdiction of the courts
      of England and Wales.
    </div>
  );
};

export default TermsOfUse;
